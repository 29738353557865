<template>
    <div class="referreds container">
        <div>
            <div class="ui simple middle aligned computer only tablet only two column grid">
                <div class="column">
                    <h3 class="ui header">
                        Referred Registrations
                        <div class="sub header">Registered referred customers</div>
                    </h3>
                </div>
                <div class="right aligned column">
                    <button class="ui black small button" type="button" @click="addRegistration"><i class="fontello-plus icon"></i> New Registration</button>
                </div>
            </div>
            <div class="ui simple middle aligned mobile only grid">
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="!mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div>
                                    <h3 class="ui header">
                                        Referred Registrations
                                        <div class="sub header">Registered referred customers</div>
                                    </h3>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="plus icon link" @click="addRegistration">
                                        <span>&plus;</span>
                                    </a>
                                    <a href="javascript:void(0);" class="icon link" @click="mobile_search = true;">
                                        <i class="search icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div style="flex: 1; min-width: 0;">
                                    <form class="ui search form" @submit.prevent="search_">
                                        <div class="ui icon fluid input" :class="{loading: loading}">
                                            <input type="text" placeholder="Name, contact no., referral code..." v-model.trim.lazy="query"/>
                                            <i class="search link icon"></i>
                                        </div>
                                    </form>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="close icon link" @click="mobile_search = false;">
                                        &times;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <div class="ui two column stackable simple grid">
                <div class="tablet only computer only column">
                    <form class="ui form" @submit.prevent="search_">
                        <div class="ui left icon action fluid input" :class="{loading: loading}">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Name, contact no., referral code..." v-model.trim.lazy="query"/>
                            <button type="submit" class="ui icon black button">
                                <i class="arrow right icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="right aligned column">
                    <div class="ui compact secondary menu">
                        <div ref="sortbydropdown" class="ui inline dropdown item">
                            <input type="hidden" :value="sorting.by" @change="changeSorting"/>
                            <span style="margin-right: 0.5em;">Sort By:</span><div class="text">Default</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="item" data-value="name">Name</div>
                                <div class="item" data-value="created">Registration Date / Time</div>
                            </div>
                        </div>
                        <button class="ui fitted item button" type="button" title="Ascending" @click="sortAsc"><i class="sort amount up icon" :class="{teal: sorting.order === 'asc'}"></i></button>
                        <button class="ui fitted item button" type="button" title="Descending" @click="sortDesc"><i class="sort amount down icon" :class="{teal: sorting.order === 'desc'}"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-container">
            <div class="ui hidden divider"></div>
            <div class="ui very basic segment" :class="{loading}">
                <div class="ui secondary teal menu">
                    <a href="javascript:void(0);" class="item" :class="{active}" @click="active = true;">Active</a>
                    <a href="javascript:void(0);" class="item" :class="{active: !active}" @click="active = false;">Archived</a>
                </div>
                <table class="ui very basic unstackable responsive referred-registration table">
                    <thead>
                        <tr>
                            <th>Applicant</th>
                            <th>Contact Number</th>
                            <th class="three wide">Referral Code</th>
                            <th>Source</th>
                            <th>Remark</th>
                            <th></th>
                        </tr>
                    </thead>
                    <template v-if="registrations.length">
                        <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                            <tr v-for="registration in registrations" :key="`registration_tr_${registration.id}`">
                                <td class="top aligned">
                                    <strong>{{registration.name}}</strong>
                                    <div class="meta"><span class="location">{{$moment(registration.created).format("DD MMM YYYY hh:mmA")}}</span></div>
                                </td>
                                <td data-title="Contact Number">
                                    <div>
                                        <template v-if="registration.contact_preference === 'whatsapp'">
                                            <a :href="registration.contact_no|whatsapplink" target="_blank">{{registration.contact_no}} <i class="green whatsapp icon"></i></a>
                                        </template>
                                        <template v-else>
                                            <a :href="`tel:${registration.contact_no}`">{{registration.contact_no}}</a>
                                        </template>
                                    </div>
                                    <div>
                                        <label class="ui mini label" v-if="registration.contact_preferred_day === 'weekday'">MON - FRI</label>
                                        <label class="ui mini label" v-else-if="registration.contact_preferred_day === 'saturday'">SAT</label>
                                        <label class="ui mini label" v-else-if="registration.contact_preferred_day === 'sunday'">SUN</label>

                                        <label class="ui mini label" v-if="registration.contact_preferred_time === 'morning'">Morning</label>
                                        <label class="ui mini label" v-else-if="registration.contact_preferred_time === 'afternoon'">Afternoom</label>
                                        <label class="ui mini label" v-else-if="registration.contact_preferred_time === 'evening'">Evening</label>
                                    </div>
                                </td>
                                <td :data-title="registration.referral_code? 'Referral Code' : ''">
                                    <label class="ui basic referral-code fluid large center aligned label" v-if="registration.referral_code">
                                        <span class="ui left corner mini label" :class="{red: active}">
                                            <i class="heart icon"></i>
                                        </span>
                                        <small class="ui light text">Code:</small>
                                        <div class="ui hidden fitted divider"></div>
                                        {{registration.referral_code}}
                                    </label>
                                </td>
                                <td class="top aligned" :data-title="registration.referer_url? 'Source' : ''">
                                    <a target="_blank" :href="registration.referer_url" v-if="registration.referer_url">
                                        {{registration.referer_url || ""}}
                                    </a>
                                </td>
                                <td class="top aligned" :data-title="registration.remark? 'Remark' : ''">
                                    <div class="registration-remark" :title="registration.remark || false">
                                        {{registration.remark || ""}}
                                    </div>
                                </td>
                                <td class="right aligned">
                                    <div class="ui text compact action menu">
                                        <div class="item">
                                            <button class="ui circular icon alt teal button" type="button" title="Move to Archived" @click="toggleStatus(registration)" v-if="active"><i class="archive icon"></i></button>
                                            <button class="ui circular icon alt teal button" type="button" title="Activate" @click="toggleStatus(registration)" v-else><i class="undo icon"></i></button>
                                        </div>
                                        <div class="ui right dropdown fitted item">
                                            <i class="ellipsis vertical teal icon"></i>
                                            <div class="menu">
                                                <a class="item" href="javascript:void(0);" @click="editRegistration(registration)">Edit Registration</a>
                                                <a class="item" href="javascript:void(0);" @click="deleteRegistration(registration)" v-if="isAdmin"><span class="ui red text">Delete Registration</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </transition-group>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td colspan="6">No registration found.</td>
                        </tr>
                    </tbody>
                    <tfoot v-show="registrations.length">
                        <tr>
                            <th colspan="6" class="right aligned">
                                <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage" always-show/>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div ref="registrationmodal" class="ui registration modal">
            <div class="content">
                <h3 class="ui header">{{registration_form.id? "Update Registration" : "Create New Registration"}}</h3>
                <div class="ui hidden divider"></div>
                <form :id="`registrationform${_uid}`" class="ui form" :class="{loading: saving}" @submit.prevent="saveRegistration">
                    <div class="two fields">
                        <div class="required field">
                            <label>Name</label>
                            <input type="text" v-model.trim="registration_form.name" required/>
                        </div>
                        <div class="required field">
                            <label>Phone Number</label>
                            <input type="tel" v-model.trim="registration_form.contact_no" required/>
                        </div>
                    </div>

                    <div class="required field">
                        <label>Preference of Contact</label>
                        <div class="four fields">
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preference" tabindex="0" class="hidden" value="whatsapp" v-model="registration_form.contact_preference" required>
                                    <label>WhatsApp</label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preference" tabindex="0" class="hidden" value="phonecall" v-model="registration_form.contact_preference" required>
                                    <label>Phone Call</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="required field">
                        <label>Preferred Time</label>
                        <div class="four fields">
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preferred_time" tabindex="0" class="hidden" value="morning" v-model="registration_form.contact_preferred_time" required>
                                    <label>Morning</label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preferred_time" tabindex="0" class="hidden" value="afternoon" v-model="registration_form.contact_preferred_time" required>
                                    <label>Afternoon</label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preferred_time" tabindex="0" class="hidden" value="evening" v-model="registration_form.contact_preferred_time" required>
                                    <label>Evening</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="required field">
                        <label>Preferred Day</label>
                        <div class="four fields">
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preferred_day" tabindex="0" class="hidden" value="weekday" v-model="registration_form.contact_preferred_day" required>
                                    <label>Weekdays</label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preferred_day" tabindex="0" class="hidden" value="saturday" v-model="registration_form.contact_preferred_day" required>
                                    <label>Saturday</label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="contact_preferred_day" tabindex="0" class="hidden" value="sunday" v-model="registration_form.contact_preferred_day" required>
                                    <label>Sunday</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="two fields">
                        <div class="field">
                            <label>Referral Code</label>
                            <input type="text" placeholder="Optional" v-model.trim="registration_form.referral_code"/>
                        </div>
                    </div>

                    <div class="field">
                        <label>Remark</label>
                        <textarea placeholder="Optional" v-model.trim="registration_form.remark" rows="3"></textarea>
                    </div>
                </form>
            </div>
            <div class="actions">
                <button type="submit" :form="`registrationform${_uid}`" class="ui submit teal button">{{registration_form.id? "Update" : "Submit"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";

import PaginationMenu from "@/components/PaginationMenu";

export default {
    name: "referred-registrations",
    components: {
        PaginationMenu
    },
    data() {
        return {
            mobile_search: false,
            loading: true,
            saving: false,
            query: "",
            registrations: [],
            registration_form: {},
            sorting: {
                by: "created",
                order: "desc"
            },
            active: true,
            pagination: {
                total_count: 0,
                page: 1,
                size: 10
            },
            opened_modals: [],
            history_popped: false,
            scrollY: window.scrollY || 0,
            search_: debounce(this.search, 100)
        };
    },
    created() {
        this.search_();
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$el).find(".ui.radio.checkbox").checkbox();
        $(this.$refs.sortbydropdown).dropdown();
        $(this.$refs.registrationmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "registrationmodal_opened");
                this.opened_modals.push("registrationmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "registrationmodal";
                });
            }
        });
    },
    activated() {
        this.search().finally(() => {
            if(this.scrollY) {
                this.$nextTick(() => {
                    window.scroll(0, this.scrollY);
                });
            };
        });
    },
    updated() {
        $(this.$el).find(".ui.action.menu:not(.disabled) .ui.dropdown").dropdown();
    },
    beforeRouteLeave(to, from, next) {
        this.scrollY = window.scrollY;
        next();
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    computed: {
        search_params() {
            let search_params = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.sorting.by,
                order: this.sorting.order,
                filter: JSON.stringify({
                    active: this.active
                })
            };

            if(this.query) {
                search_params.search = JSON.stringify({
                    referral_code: this.query,
                    name: this.query,
                    contact_no: this.query
                });
            }

            return search_params;
        }
    },
    filters: {
        whatsapplink(value) {
            if(!value) {
                return "";
            }

            let phone = value.replaceAll("[\\D]", "");
            let prefix = phone.startsWith("0")? "6" : "60";
            return `https://wa.me/${prefix}${phone}`;
        }
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        addRegistration() {
            this.registration_form = {};
            $(this.$refs.registrationmodal).modal("show");
        },
        saveRegistration() {
            this.saving = true;

            let registration = this.registration_form;
            if(registration.id) {
                this.$http.put(`affiliates/${registration.id}`, registration).then((response) => {
                    this.$toasted.success("Registration updated successfully.", {
                        duration: 3000
                    });

                    this.search_();
                    $(this.$refs.registrationmodal).modal("hide");
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Failed to update registration.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                this.$http.post("affiliates", Object.assign({}, registration, {
                    referer_url: window.location.href
                })).then((response) => {
                    this.$toasted.success("Registration created successfully.", {
                        duration: 3000
                    });

                    this.search_();
                    $(this.$refs.registrationmodal).modal("hide");
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Failed to create registration.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            }
        },
        search() {
            this.loading = true;

            const params = this.search_params;
            return this.$http.get("affiliates", {
                params
            }).then((response) => {
                this.registrations = response.data;

                const total_count = response.headers["x-total-count"];
                this.pagination.total_count = total_count? +total_count : this.registrations.length;
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to list referred registrations. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        changeSorting(event) {
            this.sorting.by = event.target.value;
        },
        sortAsc() {
            this.sorting.order = "asc";
        },
        sortDesc() {
            this.sorting.order = "desc";
        },
        changePage(page) {
            this.pagination.page = page;
        },
        editRegistration(registration) {
            this.registration_form = Object.assign({}, registration);
            $(this.$refs.registrationmodal).modal("show");
        },
        deleteRegistration(registration) {
            if(this.isAdmin) {
                this.$confirm({
                    title: "Remove Registration",
                    message: `Are you sure you want to remove registration <strong>${registration.name}</strong>?`,
                    button: {
                        yes: "Yes",
                        no: "Cancel"
                    },
                    callback: (confirmed) => {
                        if(confirmed) {
                            this.$http.delete(`affiliates/${registration.id}`).then((response) => {
                                this.$toasted.success(`Registration ${registration.name} deleted successfully.`, {
                                    duration: 3000
                                });

                                this.search_();
                            }).catch((error) => {
                                console.error(error);

                                this.$toasted.error(`Failed to delete registration ${registration.name}.`, {
                                    duration: 3000
                                });
                            });
                        }
                    }
                });
            }
        },
        toggleStatus(registration) {
            this.$confirm({
                title: `${registration.active? "Archive" : "Activate"} Registration`,
                message: `Are you sure you want to ${registration.active? "archive" : "activate"} <strong>${registration.name}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.put(`affiliates/${registration.id}`, {
                            active: !registration.active
                        }).then((response) => {
                            this.$toasted.success(`Registration ${registration.active? "archived" : "activated"} successfully.`, {
                                duration: 3000
                            });

                            this.search_();
                        }).catch((error) => {
                            registration.active = !registration.active;
                            console.error(error);

                            this.$toasted.error(`Failed to ${registration.active? "archive" : "activate"} registration.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        }
    },
    watch: {
        search_params: {
            deep: true,
            handler(search_params, _search_params) {
                if(search_params.search !== _search_params.search || search_params.filter !== _search_params.filter) {
                    if(this.pagination.page === 1) {
                        this.search_();
                    } else {
                        this.$refs.paginationmenu.stepTo(1);
                    }
                } else {
                    this.search_();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.referreds.container {
    background: #fff;
}

.ui.mobile.only.grid {
    position: relative;
}

.mobile.action.links {
    display: inline-flex;
    align-items: flex-start;

    .icon.link {
        font-size: 1.2rem;
        padding: 0.35rem;
        display: inline-block;

        &.plus, &.close {
            font-size: 2.25rem;
        }

        .icon {
            margin: 0;
        }
    }
}

.ui.referral-code.label {
    border-radius: 1px;
    border-style: dashed;
    border-width: 2px;
    position: relative;

    .ui.corner.label {
        margin-top: -2px;
        margin-left: -2px;
    }
}

.meta {
    margin-top: 0.25em;
    color: rgba(0,0,0,.4);
    font-size: 0.96em;
}

.registration-remark {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        max-width: 320px;
    }
}

.ui.action.menu {
    .ui.dropdown {
        .menu {
            > .active.item,
            > .selected.item {
                font-weight: 400 !important;
                color: rgba(0,0,0,.87)!important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        min-height: 0;
    }
}

@media only screen and (max-width: 767px) {
    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                td:first-child {
                    padding-right: 2em; //to give way for the action menu
                }

                td:empty {
                    display: none;
                }

                .ui.action.menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .list-container {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fbf7f2;/*#fafff8;*/
        border-radius: 20px 20px 0 0;
    }
}
</style>